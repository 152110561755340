import React from "react";
import { useNavigate } from "react-router-dom";
import * as Controller from "../../controllers/login-controller";
import "./login.css";

const Login = () => {
  const router = useNavigate();
  const [NombreUsuario, setNombreUsuario] = React.useState("");
  const [Clave, setClave] = React.useState("");
  const [ValNombreUsuario, setValNombreUsuario] = React.useState(0);
  const [ValClave, setValClave] = React.useState(0);
  const [ValMensajeLogin, setValMensajeLogin] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  /*const handleLogin = () => {
    // Lógica de autenticación aquí
    // Si las credenciales son válidas, redirige a la pantalla principal
    router('/main');
  };*/

  return (
    <div className="split-screen">
      <div className="left">
        <section className="copy">
          <h3>Aplicación administrativa</h3>
        </section>
        {loading && (
          <div className="loading-overlay">
            <div className="loading-popup">
              <p>Cargando...</p>
            </div>
          </div>
        )}
      </div>
      <div className="right">
        <form style={{ marginTop: 30 }}>
          <section className="copy">
            <h2>Inicio Sesión</h2>
          </section>
          <div className="input-container name">
            <label htmlFor="usuario">Usuario</label>
            <input
              id="usuario"
              name="usuario"
              type="text"
              className={ValClave == 2 ? "errorlogin" : ""}
              placeholder="Usuario"
              autoComplete="off"
              value={NombreUsuario}
              onChange={(e) => {
                setNombreUsuario(e.currentTarget.value);
              }}
            />
          </div>
          <div className="input-container password">
            <label htmlFor="clave">Clave</label>
            <input
              id="clave"
              type="password"
              className={ValClave == 2 ? "errorlogin" : ""}
              placeholder="Clave"
              value={Clave}
              autoComplete="off"
              onChange={(e: any) => {
                setClave(e.currentTarget.value);
              }}
            />
            <i className="far fa-eye-slash"></i>
          </div>
          <div className="input-container">
            <div style={{ textAlign: "center", marginTop: 15, marginBottom: 15 }}>
              <span className="mensajeerror">
                {ValNombreUsuario == 2 ||
                  ValClave == 2 ||
                  ValMensajeLogin !== ""
                  ? ValMensajeLogin
                  : ""}
              </span>
            </div>
          </div>

          <button
            className="custom-button"
            type="button"
            onClick={() =>
              Controller.toLogin(
                router,
                NombreUsuario,
                Clave,
                setValNombreUsuario,
                setValClave,
                setValMensajeLogin,
                setLoading
              )
            }
          >
            Ingresar
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
