import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "./sidebar";
import React, { useState } from "react";
import * as Controller from "../../controllers/usuario-controller";
import { ToastContainer, toast } from 'react-toastify';    

import "bootstrap/dist/css/bootstrap.min.css";
import "./main.css";

const UserContextDataNew = {
  Usuario: -1
};

export const UserContext = React.createContext(UserContextDataNew);

const Main = () => {
  let navigate = useNavigate();
  const [Usuario, setUsuario] = useState(UserContextDataNew);

  function handleBxMenu(e: any) {
    var sidebar = document.querySelector(".sidebar");
    sidebar!.classList.toggle("close");
  }

  function handleCerrarSesion(e: any){
    navigate("/")
  }

  React.useEffect(() => {
    Controller.getUsuarioInfo(setUsuario);
  }, []);

  return (
    <div>
      <Sidebar Usuario={Usuario} />
      <ToastContainer />
      <section className="home-section">
        <div className="home-content" style={{ background: "white" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%"
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <i className="bx bx-menu" onClick={handleBxMenu}></i>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "right",
                alignItems: "center",
                paddingRight: 15,

              }}
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                alt="Account"
                style={{ width: 48, height: 36 }}
              />
              <div className="name-job" style={{marginLeft: 5, marginRight: 5}}>
                <div className="profile_name_v">{Usuario.Usuario}</div>
              </div>
              <i className="bx bx-log-out logout_icon" onClick={handleCerrarSesion}></i>
            </div>
          </div>
        </div>
        <div style={{ marginTop: 15 }}>
          <UserContext.Provider value={Usuario}>
            <Outlet />
          </UserContext.Provider>
        </div>
      </section>
    </div>
  );
};

export default Main;
