import { NavigateFunction } from 'react-router-dom';
import * as Repository from "../repositories/usuario-repository";


export async function getUsuarioInfo(setUsuario: any) {
  var dataResponse = await Repository.getUsuarioInfo();

  setUsuario({
    Usuario: dataResponse.usuario
  });
}

