import { constants } from "buffer";
import React from "react";
import { Link } from "react-router-dom";
import { PERFIL_USUARIO } from "../../utils/contants";

const Sidebar = ({ Usuario }: { Usuario: any }) => {
  var optionsConfiguracion;
  var optionsAnalisis;
  var optionsPersonal;
  var optionsDocumentos;
  var optionsSoporte;
  var optionsIntegracion;

  var optionsConfiguracionfilteredList;
  var optionsAnalisisfilteredList;
  var optionsPersonalfilteredList;
  var optionsDocumentosfilteredList;
  var optionsSoportefilteredList;
  var optionsIntegracionfilteredList;

  var PerfilUsuario = Usuario.Perfilusuario;
  var opcionesMenuList = Usuario.OpcionesMenu;

  optionsConfiguracion = [
    {
      code: "OPC_SACLIENTE",
      path: "/main/pregunta/consulta",
      text: "Preguntas",
    },
  ];


  optionsConfiguracionfilteredList = optionsConfiguracion

  function handleArrow(e: any) {
    let arrowParent = (e.target as HTMLElement).parentElement!.parentElement;
    arrowParent!.classList.toggle("showMenu");
  }

  return (
    <div className="sidebar">
      <div className="logo-details">
        {/*<i className="bx bxl-c-plus-plus"></i>*/}
        <img src="../../images/logo_toyota_blanco.png" style={{width: 30, marginRight:15, marginLeft:15}} />
        <span className="logo_name">ADMIN</span>
      </div>
      <ul className="nav-links">
        {optionsConfiguracionfilteredList != null && optionsConfiguracionfilteredList.length > 0 ? (
          <li key={100}>
            <div className="iocn-link">
              <a href="#">
                <i className="bx bx-collection"></i>
                <span className="link_name">Datos</span>
              </a>
              <i
                key={0}
                className="bx bxs-chevron-down arrow"
                onClick={handleArrow}
              ></i>
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name" href="#">
                  Datos
                </a>
              </li>
              {optionsConfiguracionfilteredList != null &&
                optionsConfiguracionfilteredList.map((item: any, index: any) => {
                  return (
                    <li>
                      <Link key={index} to={item.path}>
                        {item.text}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </li>
        ) : (
          <></>
        )}
      </ul>
    </div>
  );
};

export default Sidebar;
