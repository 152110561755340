import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";
type Usuario = {
  IdUsuario: string,
  Nombres: string,
  APellidos: string,
  NombresUsuario: string,
  Estado: number,
};
export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<Usuario>[]>(
    () => [
      {
        accessorKey: 'IdUsuario',
        header: 'ID',
        size: 30,
        muiTableBodyCellProps: {
          align: 'center',
        },
      },

      {
        accessorKey: 'NombresPerfil',
        header: 'Perfil',
        size: 200,
      },     
      {
        accessorKey: 'Nombres',
        header: 'Nombres',
        size: 250,
      },
      {
        accessorKey: 'Apellidos',
        header: 'Apellidos',
        size: 250,
      },
      {
        accessorKey: 'NombreUsuario',
        header: 'Nombre Usuarios',
        size: 200,
      },

     
      {
        accessorKey: 'NombreTipoDocumentoIdentidad',
        header: '  Tipo Documento',
        size: 200,
      },
      {
        accessorKey: 'NumeroDocumentoIdentidad',
        header: 'Numero  Documento',
        size: 200,
      },

      {
        accessorKey: 'Correo',
        header: 'Correo Electronico',
        size: 200,
      },
    ],
    [],
  );
  ;
  return columns
}


export async function getUsuarioInfo() {
  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_USUARIO_INFO , "")
  console.log(dataResponse)
  return dataResponse.data;
}
