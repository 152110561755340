"use client";

import React, { useMemo, useContext } from "react";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Email as EmailIcon,
  LockReset as LockResetIcon,
  RestartAlt as RestartAltIcon,
} from "@mui/icons-material";
import ContentHeader from "../layout/ContentHeader";
import ContentBody from "../layout/ContentBody";
import * as Controller from "../../../controllers/pregunta-controller";
import * as Repository from "../../../repositories/pregunta-repository";
import SelectSimple from "../layout/SelectSimple";
import { UserContext } from "../../main/main-screen";
import Textbox from "../layout/Textbox";
import { OPCIONES_MENU } from "../../../utils/contants";
import * as Validation from "../../../utils/validation";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es"; // the locale you want
registerLocale("es", es); // register it with the name you want  

export default function ConsultaUsuario(): React.JSX.Element {
  const router = useNavigate();

  //const data = useContext(MyContext);
  const [FilterEmpresa, setFilterEmpresa] = React.useState("");
  const [FilterApeNom, setFilterApeNom] = React.useState("");
  const [FilterNombreUsuario, setFilterNombreUsuario] = React.useState("");
  const [ListaComboEmpresa, setListaComboEmpresa] = React.useState([]);
  const [ListaConsulta, setListaConsulta] = React.useState([]);
  //const [Perfilusuario, setPerfilusuario] = React.useState(0);
  const [FileExcel, setFileExcel] = React.useState(null);
  const [FilterFechaDesde, setFilterFechaInicio] = React.useState(new Date)
  const [FilterFechaHasta, setFilterFechaHasta] = React.useState(new Date)
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    Controller.doConsultar(
      setListaConsulta,
      FilterFechaDesde,
      FilterFechaHasta,
      setLoading
    );
  }, []);

  function onChangeFileUpload(e: any) {
    var fileToUpload = e.target.files[0];
    setFileExcel(fileToUpload);
  }
  return (
    <div className="content-wrapper">
      {loading && (
          <div className="loading-overlay">
            <div className="loading-popup">
              <p>Cargando...</p>
            </div>
          </div>
        )}
      <ContentHeader
        title={"Consulta Pregunta"}
        homePath={"Home"}
        currentPath={"Mantenimiento Pregunta"}
      ></ContentHeader>
      <ContentBody title={"Consulta Pregunta"}>

        <div className="row">
          <div className="col-lg-2">
            <label>Desde </label>
          </div>
          <div className="col-lg-3">
            <div className="form-group">
              <DatePicker locale="es" className='form-control-text' selected={FilterFechaDesde} onChange={(date: any) => setFilterFechaInicio(date)} dateFormat={"dd/MM/yyyy"} />
            </div>
          </div>
          <div className="col-lg-2">
            <label>Hasta </label>
          </div>
          <div className="col-lg-3">
            <div className="form-group">
              <DatePicker locale="es" className='form-control-text' selected={FilterFechaHasta} onChange={(date: any) => setFilterFechaHasta(date)} dateFormat={"dd/MM/yyyy"} />
            </div>
          </div>
        </div>
        <div className="row mt-2 mb-2">
          <div className="col-lg-6">
            <div className="form-group">
              <button
                className="btn btn-info"
                onClick={() =>
                  Controller.doConsultar(
                    setListaConsulta,
                    FilterFechaDesde,
                    FilterFechaHasta,
                    setLoading
                  )
                }
              >
                Consultar
              </button>
              <button
                className="btn btn-success"
                onClick={() =>
                  Controller.doExportar(
                    FilterFechaDesde,
                    FilterFechaHasta,
                    setLoading
                  )
                }
              >
                Exportar
              </button>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-8">
                <div className="mt-2">
                </div>
              </div>
              <div className="col-lg-4">

              </div>
              <div className="col-lg-6">
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <MaterialReactTable
              columns={Repository.GetColumns()}
              data={ListaConsulta}
            />
          </div>
        </div>
      </ContentBody>
    </div>
  );
}
