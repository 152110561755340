//export const URL_BASE_ADMIN = "https://localhost:44325/api/";

export const URL_BASE_ADMIN = "https://apiservice.convenciondeconcesionariostoyota.com/api/";
export const API_KEY = "B5FF40FF-5527-4053-B9BC-D6B81F70E635";
//export const TOKEN = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6Im9saWJlcnQiLCJuYW1laWQiOiJmYWJhYjk0NC04MWI4LTQyZGUtOWVhMS01MTgwYmE2N2I1OTMiLCJnaXZlbl9uYW1lIjoib2xpYmVydCBnYXJjaWEiLCJqdGkiOiI3MGM3MGI2MC0yMGJlLTQ1YjktOGRiMy1kOTBhOTMzMDVmODgiLCJJZFVzdWFyaW8iOiIxMyIsIm5iZiI6MTcwMTI5NTYwOSwiZXhwIjoxNzAxMzgyMDA5LCJpYXQiOjE3MDEyOTU2MDl9.mGtwMtRpawbKLAfjCVdW2b-GwebO8_-GQaE9R87Q7VQW_jisMD2pjeQZvR3YMgmALBUbCCIhwR2mJfNjNX8YdfN3kTuDPoWq6xOqYHonoiq5nGQj3Jp02Fvhfj-josGwfYQ3W7FGDtFVfTPtXo-kSjVbX8akRnoXfYooMqBt6EM3ImTiauJyXmTRc6HMwJSrD3TFgBMmTZswCN7z2wkcvdTyayGlan35hOOgDAUFSBqnnXPe9MWd-MG5HTFgFljO7QMfmqAaTSd-VdqxxfAB5ckJ9lTgMiDZM_dm56_BBO7e4SxAcZH94dzcPELjOfkJx9mFnhW6kR4jQ4p2rg__yA";

export const ListEndpoint = {
  LOGIN: "auth/login",
  GET_USUARIO_INFO: "usuario/GetUsuarioInfo",

  //MARCA
  GET_LISTA_PREGUNTA: "administracion/listapregunta",
  GET_LISTA_PREGUNTA_EXCEL: "administracion/listapreguntaexcel",

};
