import { useMemo } from 'react';
import { type MRT_ColumnDef } from 'material-react-table';
import * as ClientAPI from "../utils/methods";
import * as Endpoints from "../api/endpoint";
import Moment from 'moment';
import moment from 'moment';

type Pregunta = {
  fechaHora: Date;
};
export function GetColumns() {
  const columns = useMemo<MRT_ColumnDef<Pregunta>[]>(
    () => [
      {
        id: 'fechaHora',
        header: 'Fecha hora',
        size: 100,
        accessorFn: (row) => {
          return moment(row.fechaHora).format("DD/MM/YYYY HH:mm:ss");
        },
      },
      {
        accessorKey: 'nombreApellido',
        header: 'Cliente',
        size: 200,
      },
      {
        accessorKey: 'concesionario',
        header: 'Concesionario',
        size: 300,
      },
      {
        accessorKey: 'pregunta',
        header: 'Pregunta',
        size: 800,
      },

    ],
    [],
  );
  return columns
}
type PreguntaDetalle = {
  CodePregunta: string,
  DesOpcionRespuesta: string,
};
export function GetMColumns() {
  const columns = useMemo<MRT_ColumnDef<PreguntaDetalle>[]>(
    () => [
      {
        accessorKey: 'DesPregunta',
        header: 'Descripcion Pregunta',
        size: 300,
      },
      {
        accessorKey: 'DesOpcionRespuesta',
        header: 'Opcion Respuesta ',
        size: 200,
      },

      {
        accessorKey: 'DesFoto',
        header: 'Tomar Foto',
        size: 50,
      },
    ],
    [],
  );
  ;
  return columns
}


export async function getData(FilterFechaDesde: Date, FilterFechaHasta: Date) {
    var CFilterFechaDesde = Moment(FilterFechaDesde).format("DD-MM-YYYY") 
    var CFilterFechaHasta = Moment(FilterFechaHasta).format("DD-MM-YYYY")

  var dataResponse = await ClientAPI.RequestGet(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_PREGUNTA + "/" + CFilterFechaDesde + "/" + CFilterFechaHasta, "")
  if (dataResponse.data != null) {
    return dataResponse.data;
  } else {
    return []
  }
}

export async function doExport(FilterFechaDesde: Date, FilterFechaHasta: Date) {
    var CFilterFechaDesde = Moment(FilterFechaDesde).format("DD-MM-YYYY") 
    var CFilterFechaHasta = Moment(FilterFechaHasta).format("DD-MM-YYYY")
    var dataResponse = await ClientAPI.RequestDownload(Endpoints.URL_BASE_ADMIN + Endpoints.ListEndpoint.GET_LISTA_PREGUNTA_EXCEL + "/" + CFilterFechaDesde + "/" + CFilterFechaHasta, 'DescargarExcelPregunta.xlsx')
}

