import { NavigateFunction } from 'react-router-dom';
import * as Repository from '../repositories/login-repository';


export async function toLogin(router: NavigateFunction, NombreUsuario: string, Clave: string, setValNombreUsuario: any, setValClave: any, setValMensajeLogin: any, setLoading: any) {
    var objLogin = {
        Usuario: NombreUsuario,
        Clave: Clave
    }

    setValMensajeLogin("")
    setValNombreUsuario(1)
    setValClave(1)
    if (NombreUsuario.trim() == "") {
        setValNombreUsuario(2)
    }

    if (Clave.trim() == "") {
        setValClave(2)
    }

    if (NombreUsuario.trim() == "" || Clave.trim() == "") {
        setValMensajeLogin("Debe ingresar usuario y clave")
        return false
    }

    setLoading(true)
    var dataResponse = await Repository.doLogin(objLogin)
    if (dataResponse) {
        setLoading(false)
        localStorage.setItem("TOKEN_JWT", dataResponse)
        router('/main')
    } else {
        setLoading(false)
        setValMensajeLogin("Usuario o clave incorrectos")
    }

}

export async function getUsuarioInfo(setPerfilUsuario: any) {
    var dataResponse = await Repository.getUsuarioInfo()
    setPerfilUsuario(dataResponse.Usuario)
}
