
import { NavigateFunction } from 'react-router-dom';
import * as Repository from "../repositories/pregunta-repository";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { ToastContainer, toast } from 'react-toastify';    
import 'react-toastify/dist/ReactToastify.css';
import { CODE_SERVER_RETURN } from '../utils/contants';


export function toConsulta(router: NavigateFunction) {
    router("/main/pregunta/consulta")
}
export async function doConsultar(setListaConsulta: any, FilterFechaDesde: Date, FilterFechaHasta: Date, setLoading: any) {
    setLoading(true)
    var dataResponse = await Repository.getData(FilterFechaDesde, FilterFechaHasta)
    setLoading(false)
    setListaConsulta(dataResponse)
}

export async function doExportar(FilterFechaDesde: Date, FilterFechaHasta: Date, setLoading: any) {
    setLoading(true)
    var dataResponse = await Repository.doExport(FilterFechaDesde, FilterFechaHasta)
    setLoading(false)
}

